import { ATTENDANCE_COLUMNS } from "./attendance.column";
import { FEEDBACK_COLUMNS } from "./feedback.column";
import { PROJECTS_COLUMNS } from "./project.column";
import { STUDENTS_COLUMNS } from "./students.columns";

export const COLUMNS = {
  student: STUDENTS_COLUMNS,
  attendace: ATTENDANCE_COLUMNS,
  projects: PROJECTS_COLUMNS,
  feedback: FEEDBACK_COLUMNS,
};
