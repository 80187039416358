export type TFeedbackValues = {
  feedback: string;
  employ: { value: string; label: string; email: string };
  _id?: string;
};

export const AddFeedbackValues: TFeedbackValues = {
  feedback: "",
  employ: { value: "", label: "", email: "" },
};
