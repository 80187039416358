import { authUrls } from "./auth.url";
import { employeeUrls } from "./employee.url";
import { attendanceUrls } from "./attendance.url";
import { settingsUrl } from "./settings.url";
import { qrUserUrls } from "./qruser";
import { holidayUrls } from "./holiday.url";
import { leaveUrls } from "./leave.url";
import { projectsUrls } from "./projects.url";
import { feedbackUrls } from "./feedback.url";

export const apiUrls = {
  ...authUrls,
  ...employeeUrls,
  ...attendanceUrls,
  ...settingsUrl,
  ...qrUserUrls,
  ...holidayUrls,
  ...leaveUrls,
  projects: projectsUrls,
  feedback: feedbackUrls,
};
