import { Box, Stack, Grid } from "@mantine/core";

import React, { FC, memo } from "react";

import ThemeLoader from "../../../component/loader/ThemeLoader";
import NoData from "../../../component/no-data/NoData";
import AttendanceCardItem from "../../../component/attendance-card-item/AttendenceCardItem";

interface IAttendanceCard {
  loading: boolean;
  attendance: TAttendanceData[];
}

const AttendanceCard: FC<IAttendanceCard> = ({ loading, attendance }) => {
  return (
    <Box>
      <Box mt={20}>
        <Stack mt={20} h="85vh" bg="var(--mantine-color-blue-light)">
          {loading ? (
            <ThemeLoader loading={loading} />
          ) : (
            <Grid gutter="lg">
              {attendance.map((item) => (
                <AttendanceCardItem key={`${item._id}`} item={item} />
              ))}
            </Grid>
          )}

          {!loading && attendance.length === 0 && (
            <NoData title="No Attendance Available" />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default memo(AttendanceCard);
