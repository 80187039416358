import React, { useEffect, useState } from "react";
import InputField from "../../component/form/input-field/InputField";
import { useForm, yupResolver } from "@mantine/form";
import ThemeButton from "../../component/button/ThemeButton";
import {
  Box,
  createStyles,
  Text,
  Title,
  Select,
  Button,
  Flex,
  HoverCard,
  Accordion,
} from "@mantine/core";
import { useUpdateSettings } from "../../hooks/settings/mutation/updateSettings.mutation";
import { TSettingsValues } from "../../form/initial-value/settings.values";
import { useGetSettings } from "../../hooks/settings/query/getSettings.query";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../form/initial-value";
import { validations } from "../../form/validation";
import { useGenerateWorkApiTokenMutation } from "../../hooks/settings/mutation/generateWorkApiToken";
import { queryClient } from "../..";
import { getFirstTwoSaturdayOfYear } from "../../utils/holiday/getFirstTwoSaturdays";
import Textarea from "../../component/form/text-area/Textarea";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import { useUpdateReferenceSaturday } from "../../hooks/settings/mutation/updateReferenceSaturday.mutation";
import FRichTextEditor from "../../component/text-editor/FRichTextEditor";
import Birthday from "./components/Birthday";

const Settings = () => {
  const { getInputProps, setValues, onSubmit, setFieldValue, values } = useForm(
    {
      initialValues: initialValue.settingsIniValues,
      validateInputOnBlur: true,
      validateInputOnChange: true,
      validate: yupResolver(validations.settings),
    }
  );

  const { classes } = useStyles();
  const { mutateAsync: updateSettings } = useUpdateSettings();
  const { mutateAsync: updateReferenceSaturday } = useUpdateReferenceSaturday();
  const { mutateAsync: GenerateWorkApiToken } =
    useGenerateWorkApiTokenMutation();
  const { data: settingsData, isLoading: settingsLoading } = useGetSettings();
  const { first, second } = getFirstTwoSaturdayOfYear(new Date());

  const [selectData, setSelectData] = useState([
    { value: first.toISOString(), label: first.toDateString() },
    { value: second.toISOString(), label: second.toDateString() },
  ]);
  useEffect(() => {
    if (!settingsLoading && settingsData && settingsData.data) {
      if (settingsData.data.referenceSaturday) {
        settingsData.data.referenceSaturday = new Date(
          settingsData.data.referenceSaturday
        );
        const referenceSaturdayDate = new Date(
          settingsData.data.referenceSaturday
        );

        const isSaturdayInSelect = selectData.some(
          (item) => item.value === referenceSaturdayDate.toISOString()
        );

        if (!isSaturdayInSelect) {
          setSelectData((prevSelectData) => [
            {
              value: referenceSaturdayDate.toISOString(),
              label: referenceSaturdayDate.toDateString(),
            },
            ...prevSelectData,
          ]);
        }
      }
      setValues(settingsData.data);
    } else {
      setValues(initialValue.settingsIniValues);
    }
  }, [settingsData, setValues, settingsLoading]);

  const handleUpdateSaturday = async () => {
    if (!values.referenceSaturday) {
      notifications.show({
        message: "Reference Saturday is required",
        color: "red",
      });
      return;
    }
    const res = await updateReferenceSaturday(values.referenceSaturday);
    if (res.status === "success") {
      notifications.show({
        title: "Reference Updated",
        message: "Alternate Saturday Reference is updated successfully",
      });
    } else {
      notifications.show({
        title: res.title,
        message: res.message,
        color: "red",
      });
    }
  };

  const handleUpdate = async (values: TSettingsValues) => {
    try {
      const res = await updateSettings(values);
      if (res.status === "success") {
        notifications.show({
          color: "green",
          message: res.message,
        });
        setFieldValue("password", "");
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    } catch (error) {
      notifications.show({
        color: "red",
        message: (error as Error).message,
      });
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      notifications.show({
        color: "green",
        message: "Copied to clipboard!",
      });
    });
  };

  const handleGenerateWorkApiToken = async () => {
    const res = await GenerateWorkApiToken();

    if (res.status === "success") {
      await queryClient.invalidateQueries(["admin", "all-settings"]);
      notifications.show({
        message: "Token Generated Successfully",
        color: "green",
      });
    }
  };

  return (
    <Box>
      <Box bg={"#fff"} sx={{ borderRadius: 8, padding: "12px 0px 8px" }}>
        <Flex px={24} pt={8} align={"center"}>
          <Title order={3} ml={4}>
            Settings
          </Title>
        </Flex>
        <Box
          m={16}
          bg={"#fbfbfb6b"}
          sx={{
            border: "1px solid #e4e4e4",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <form onSubmit={onSubmit(handleUpdate)}>
            <Accordion
              defaultValue={"App-Build-Details"}
              classNames={{ chevron: classes.chevron }}
              chevron={<IconPlus className={classes.icon} />}
            >
              <Accordion.Item value="App-Build-Details">
                <Accordion.Control>
                  <Title order={5}>App Settings</Title>
                </Accordion.Control>
                <Accordion.Panel>
                  <Box className={classes.mainBox}>
                    <Box className={classes.input}>
                      <InputField
                        label="App Build Number"
                        getInputProps={getInputProps}
                        name="buildNumber"
                      />
                    </Box>
                    <Box className={classes.input}>
                      <InputField
                        label="Update screen title"
                        getInputProps={getInputProps}
                        name="updateTitle"
                      />
                    </Box>
                    <Box className={classes.inputMsg}>
                      <Textarea
                        label="Update screen message"
                        getInputProps={getInputProps}
                        name="updateMessage"
                      />
                    </Box>
                  </Box>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value={"Check-Time-Details"}>
                <Accordion.Control>
                  <Title order={5}>Check Time Details</Title>
                </Accordion.Control>
                <Accordion.Panel>
                  <Box className={classes.mainBox}>
                    <Box className={classes.input}>
                      <InputField
                        label="Check In Time"
                        getInputProps={getInputProps}
                        name="checkIn"
                      />
                    </Box>
                    <Box className={classes.input}>
                      <InputField
                        label="Check Out Time"
                        getInputProps={getInputProps}
                        name="checkOut"
                      />
                    </Box>
                  </Box>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="QR-Panel-Details">
                <Accordion.Control>
                  <Title order={5}>QR Panel Details</Title>
                </Accordion.Control>
                <Accordion.Panel>
                  <Box className={classes.mainBox}>
                    <Box className={classes.input}>
                      <InputField
                        label="QR Panel User Name"
                        getInputProps={getInputProps}
                        name="userName"
                      />
                    </Box>
                    <Box className={classes.input}>
                      <InputField
                        label="QR Panel Password"
                        getInputProps={getInputProps}
                        name="password"
                      />
                    </Box>
                  </Box>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="Api-Tokens">
                <Accordion.Control>
                  <Title order={5}>Tokens</Title>
                </Accordion.Control>
                <Accordion.Panel>
                  <Box className={classes.tokenMainBox}>
                    <Box className={classes.tokenInput}>
                      <Text>Work Api Token</Text>
                      <Flex className={classes.mainBox}>
                        <Text className={classes.tokenContainer}>
                          {values.ApiTokens.workApiToken}
                        </Text>
                        <Button
                          onClick={() =>
                            handleCopy(values.ApiTokens.workApiToken)
                          }
                          className={classes.copyButton}
                        >
                          Copy
                        </Button>
                        <Button
                          onClick={handleGenerateWorkApiToken}
                          className={classes.copyButton}
                        >
                          Regenrate Token
                        </Button>
                      </Flex>
                    </Box>
                  </Box>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>

            <Box className={classes.button}>
              <ThemeButton
                type="submit"
                title="Update"
                leftIcon={<IconEdit />}
              />
            </Box>
          </form>
        </Box>
      </Box>
      <Box bg={"#fff"} sx={{ borderRadius: 8, padding: "12px 0px 8px" }}>
        <Flex px={24} pt={8} align={"center"}>
          <Title order={3} ml={4}>
            Holiday Reference
          </Title>
        </Flex>
        <Box
          m={16}
          bg={"#fbfbfb6b"}
          sx={{
            border: "1px solid #e4e4e4",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <Accordion
            defaultValue={"App-Build-Details"}
            classNames={{ chevron: classes.chevron }}
            chevron={<IconPlus className={classes.icon} />}
          >
            <Accordion.Item value="Holiday-Details">
              <Accordion.Control>
                <Title order={5}>Holiday Details</Title>
              </Accordion.Control>
              <Accordion.Panel>
                <Box className={classes.mainBox}>
                  <Box className={classes.input}>
                    <HoverCard width={280} shadow="md" position="right">
                      <HoverCard.Target>
                        <Select
                          data={selectData}
                          label="Reference Alternate Saturday"
                          placeholder={
                            "Select Reference for Alternate Saturday"
                          }
                          value={values.referenceSaturday?.toISOString()}
                          onChange={(value) => {
                            if (value)
                              setFieldValue(
                                "referenceSaturday",
                                new Date(value)
                              );
                          }}
                        />
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Text size="sm">
                          Please note that changing the reference Saturday will
                          only be effecting when changes end of the month.
                        </Text>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Box>
                  <Box
                    className={classes.button}
                    onClick={handleUpdateSaturday}
                  >
                    <ThemeButton title="Update" leftIcon={<IconEdit />} />
                  </Box>
                </Box>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Box>
      </Box>

      {/* Birthday Message */}

      <Birthday />
    </Box>
  );
};

const useStyles = createStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    margin: 10,
  },
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
  },
  button: {
    padding: "20px 22px 20px ",
    display: "flex",
    justifyContent: "flex-end",
  },
  input: {
    width: "calc(50% - 8px)",
  },
  inputMsg: {
    width: "100%",
  },
  tokenMainBox: {
    display: "flex",
    width: "100%",
  },
  tokenInput: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
  },
  tokenContainer: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: "#eaeaea",
    margin: 10,
    border: "1px solid ",
    textAlign: "center",
  },
  copyButton: {
    backgroundColor: "#ff008a",
    color: "white",
    margin: 5,
    marginTop: 10,
    ":hover": {
      backgroundColor: "#d50075",
    },
  },
  icon: {
    width: "16px",
    height: "16px",
  },
  chevron: {
    "&[data-rotate]": {
      transform: "rotate(45deg)",
    },
  },
}));

export default Settings;
