import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllProjects = async (params: TQueryParams) => {
  const response: TServerResponse<TProjectsData[], unknown> = await request({
    url: apiUrls.projects.GET_ALL_PROJECT,
    method: "GET",
    params: {
      ...params.paging,
      search: params.searchParams?.search,
      searchFieldString: ["name", "projectId", "status"],
    },
  });
  return response;
};

export const useGetAllProjects = (params: TQueryParams) => {
  return useQuery(["admin", "all-projects", params], () =>
    getAllProjects(params)
  );
};
