import React from "react";
import { Badge, Stack, Text } from "@mantine/core";

export const STUDENTS_COLUMNS: TTableColumns[] = [
  { key: "rollNumber", header: "Emp Id" },
  {
    key: "name",
    header: "Name",
    renderCell(vales) {
      return (
        <Stack>
          <Text fw={vales.remoteEmployee ? "bold" : "normal"}>
            {vales.name}
          </Text>
          <Text fw={"light"} size="sm" style={{ opacity: 0.5 }}>
            {vales.mobile}
          </Text>
        </Stack>
      );
    },
  },
  { key: "email", header: "Email" },
  {
    key: "remote",
    header: "Remote Employee",
    renderCell(values) {
      return values.remoteEmployee ? (
        <Badge size="xs" style={{ color: "white" }} bg="#ff008a">
          Remote Employee
        </Badge>
      ) : (
        <Text size="xs" color="dimmed">
          No
        </Text>
      );
    },
  },
  {
    key: "dob",
    header: "DOB",
    renderCell(values) {
      const formattedDOB = values.dob
        ? new Date(values.dob)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
            .replace(/\s/g, "-")
        : "N/A";
      return <Text>{formattedDOB}</Text>;
    },
  },
  { key: "role", header: "Role" },
];
