import { Box, Text, TextInputProps } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { GetInputProps } from "@mantine/form/lib/types";
import React, { memo } from "react";

interface IDateField {
  label: string;
  getInputProps: GetInputProps<TextInputProps>;
  name: string;
  disabled?: boolean;
}

const DateField: React.FC<IDateField> = ({
  label,
  getInputProps,
  name,
  disabled,
}) => {
  return (
    <Box>
      <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
        {label}
      </Text>
      <DateInput
        valueFormat="DD/MM/YYYY"
        maw={400}
        mx="auto"
        {...getInputProps(name)}
        disabled={disabled}
      />
    </Box>
  );
};

export default memo(DateField);
