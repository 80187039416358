import React, { FC, memo } from "react";
import {
  Box,
  Text,
  Group,
  Divider,
  Center,
  Indicator,
  Tooltip,
  Grid,
  createStyles,
} from "@mantine/core";
import {
  IconCalendarEvent,
  IconClockPin,
  IconClockMinus,
  IconQuestionMark,
  IconClockHour10,
} from "@tabler/icons-react";
import moment from "moment";
import { totalTime } from "../../utils/attendance/totalTime";

interface IAttendanceCardItem {
  item: TAttendanceData;
}

const AttendanceCardItem: FC<IAttendanceCardItem> = ({ item }) => {
  const { classes } = useStyles();
  const getStatusStyle = () => {
    switch (item.status) {
      case "on time":
        return { color: "#009900", cardStatus: classes.unblocked };
      case "late":
        return { color: "#f79009", cardStatus: classes.statusBoxLate };
      case "absent":
        return { color: "#FF0000", cardStatus: classes.blocked };
      default:
        return { color: "#008080", cardStatus: classes.statusBoxInitiate };
    }
  };

  return (
    <Grid.Col span={4}>
      <Box className={`${classes.card}`} p="lg">
        <Group position="apart" align="flex-start">
          <Box>
            <Text fz="md" fw={600} color="dark">
              {item.name}
            </Text>
            <Group spacing="xs" align="center">
              <IconCalendarEvent stroke={1.75} size={12} />
              <Text fz="xs" color="dimmed">
                {moment(item.createdAt).format("DD-MMM-YYYY")}
              </Text>
            </Group>
          </Box>
          <Box className={classes.statusBox}>
            <Center>
              <Indicator
                inline
                size={10}
                position="middle-start"
                color={getStatusStyle().color}
              >
                <Text ml={10} size="xs" weight={500}>
                  {item.status}
                </Text>
              </Indicator>
            </Center>
            <Group spacing="xs" align="center">
              <IconClockHour10 stroke={2} size={12} />
              <Text ml={10} size="xs" weight={500}>
                {item.outTime ? totalTime(item.inTime, item.outTime) : "----"}
              </Text>
            </Group>
          </Box>
        </Group>

        <Divider my="sm" size="sm" />
        <Group position="apart" align="center">
          <Group spacing="xs" align="center">
            <IconClockPin stroke={2} size={12} />
            <Text fz="sm" fw={400} c="dimmed">
              In Time:
            </Text>
          </Group>
          <Group spacing="xs" align="center">
            <Text ml={10} size="xs" weight={500}>
              {item.inTime ? moment(item.inTime).format("LT") : "----"}
            </Text>
            {item.inNote && (
              <Tooltip label={item.inNote} withArrow>
                <IconQuestionMark
                  stroke={2}
                  size={14}
                  style={{ cursor: "pointer", color: "#007BFF" }}
                />
              </Tooltip>
            )}
          </Group>
        </Group>
        <Group position="apart" align="center">
          <Group spacing="xs" align="center">
            <IconClockMinus stroke={2} size={12} />
            <Text fz="sm" fw={400} c="dimmed">
              Out Time:
            </Text>
          </Group>
          <Group spacing="xs" align="center">
            <Text ml={10} size="xs" weight={500}>
              {item.outTime ? moment(item.outTime).format("LT") : "----"}
            </Text>
            {item.outNote && (
              <Tooltip label={item.outNote} withArrow>
                <IconQuestionMark
                  stroke={2}
                  size={14}
                  style={{ cursor: "pointer", color: "#007BFF" }}
                />
              </Tooltip>
            )}
          </Group>
        </Group>
      </Box>
    </Grid.Col>
  );
};

const useStyles = createStyles(() => ({
  card: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#E0E0E0",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    // borderTop: "4px solid  #0097ED",
  },
  unblocked: {
    borderColor: "#009900",
  },
  blocked: {
    borderColor: "#FF0000",
  },
  statusBox: {
    padding: "0.3rem 0.7rem",
    borderRadius: "4px",
  },
  statusBoxLate: {
    backgroundColor: "#fffaeb",
    color: "#f79009",
  },
  statusBoxInitiate: {
    backgroundColor: "#94ffff21",
    color: "#008080",
  },
}));

export default memo(AttendanceCardItem);
