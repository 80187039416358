import { Accordion, Box, createStyles, Flex, Text, Title } from "@mantine/core";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import React from "react";
import FRichTextEditor from "../../../component/text-editor/FRichTextEditor";
import ThemeButton from "../../../component/button/ThemeButton";
import { useForm } from "@mantine/form";
import { useGetSettings } from "../../../hooks/settings/query/getSettings.query";
import { useUpdateBirthdayMessage } from "../../../hooks/settings/mutation/updateBirthdayMessage.mutation";
import { notifications } from "@mantine/notifications";

const Birthday = () => {
  const { classes } = useStyles();

  const { data: settingsData, isLoading: settingsLoading } = useGetSettings();
  const { mutateAsync: updateBirthdayMessage, isLoading: updateLoading } =
    useUpdateBirthdayMessage();

  const form = useForm({
    initialValues: {
      birthdayMessage: settingsData?.data?.birthdayMessage || "",
    },
    validate: {
      birthdayMessage: (value) =>
        value.length < 10
          ? "Birthday Message must have at least 10 letters"
          : null,
    },
  });

  const handleUpdate = async (values: { birthdayMessage: string }) => {
    try {
      await updateBirthdayMessage({
        birthdayMessage: values?.birthdayMessage,
      });
      notifications.show({
        title: "Success",
        message: "Birthday message updated successfully!",
        color: "green",
      });
    } catch (error) {
      notifications.show({
        title: "Error",
        message: "Failed to update birthday message. Please try again.",
        color: "red",
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleUpdate)}>
      <Box bg={"#fff"} sx={{ borderRadius: 8, padding: "12px 0px 8px" }}>
        <Flex px={24} pt={8} align={"center"}>
          <Title order={3} ml={4}>
            Birthday
          </Title>
        </Flex>
        <Box
          m={16}
          bg={"#fbfbfb6b"}
          sx={{
            border: "1px solid #e4e4e4",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <Accordion
            defaultValue={"App-Build-Details"}
            classNames={{ chevron: classes.chevron }}
            chevron={<IconPlus className={classes.icon} />}
          >
            <Accordion.Item value="Holiday-Details">
              <Accordion.Control>
                <Title order={5}>Birthday Details</Title>
              </Accordion.Control>
              <Accordion.Panel>
                <Box className={classes.mainBox}>
                  <Box className={classes.input}>
                    <FRichTextEditor
                      content={form.values.birthdayMessage}
                      label=""
                      onChange={(value) =>
                        form.setFieldValue(`birthdayMessage`, value)
                      }
                    />
                    {form.errors[`birthdayMessage`] && (
                      <Text color="red" mt={10}>
                        {form.errors[`birthdayMessage`]}
                      </Text>
                    )}
                  </Box>
                  <Box className={classes.button}>
                    <ThemeButton
                      title="Update"
                      leftIcon={<IconEdit />}
                      type="submit"
                      loading={updateLoading}
                    />
                  </Box>
                </Box>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Box>
      </Box>
    </form>
  );
};

const useStyles = createStyles(() => ({
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
  },
  button: {
    padding: "20px 22px 20px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  input: {
    width: "calc(100% - 8px)",
  },
  icon: {
    width: "16px",
    height: "16px",
  },
  chevron: {
    "&[data-rotate]": {
      transform: "rotate(45deg)",
    },
  },
}));

export default Birthday;
