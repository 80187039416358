import {
  Box,
  Button,
  Modal,
  Select,
  Switch,
  Text,
  createStyles,
} from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm, yupResolver } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useState,
  useEffect,
} from "react";
import { notifications } from "@mantine/notifications";
import { useAddEmployeeMutation } from "../../../hooks/students/mutation/addEmployee.mutation";
import { initialValue } from "../../../form/initial-value";
import { validations } from "../../../form/validation";
import InputField from "../../../component/form/input-field/InputField";
import PasswordField from "../../../component/form/password-field/Index";
import { useUpdateEmployee } from "../../../hooks/students/mutation/updateStudent.mutation";
import { TStudentValues } from "../../../form/initial-value/addStudent.values";
import { DateInput } from "@mantine/dates";
import DateField from "../../../component/form/date-field/DateField";

export interface IStudentModalRef {
  toggleModal: () => void;
  updateData: (student: TEmployeeData) => void;
}
interface IStudentModalProps {
  reload: () => void;
}

const StudentModal = (
  props: IStudentModalProps,
  ref: ForwardedRef<IStudentModalRef>
) => {
  const [data, setData] = useState<TEmployeeData>();
  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { isLoading: addLoading, mutateAsync: addStudent } =
    useAddEmployeeMutation();
  const { isLoading: updateLoading, mutateAsync: updateStudent } =
    useUpdateEmployee();
  const { getInputProps, onSubmit, reset, setValues } = useForm({
    initialValues: initialValue.AddStudentValues,
    validate: yupResolver(validations.addStudent),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (data) {
      setValues({
        ...data,
        dob: data.dob ? new Date(data.dob) : undefined,
      });
    } else {
      setValues(initialValue.AddStudentValues);
    }
  }, [setValues, data]);

  const handleFormSubmit = useCallback(
    async (values: TStudentValues) => {
      const res = data ? await updateStudent(values) : await addStudent(values);
      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [addStudent, toggle, reload, reset, updateStudent, data]
  );
  const handelCloseModal = useCallback(() => {
    toggle();
    setData(undefined);
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleModal: handelCloseModal,
        updateData: (d) => setData(d),
      };
    },
    [handelCloseModal]
  );

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: " all 0.2s ease-in-out 0s;",
          },
        },
        header: {
          zIndex: 1,
        },
      }}
      opened={opened}
      onClose={handelCloseModal}
      title={data ? "Edit Employee" : "Add Employee"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <InputField label="Name" name="name" getInputProps={getInputProps} />
        <InputField label="Email" name="email" getInputProps={getInputProps} />
        <InputField
          label="Mobile no."
          name="mobile"
          getInputProps={getInputProps}
        />
        <DateField label="D.O.B" name="dob" getInputProps={getInputProps} />
        <Box>
          <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
            Role
          </Text>
          <Select
            searchable
            nothingFound="No options"
            data={[
              "CEO",
              "DevOps",
              "DevOps Intern",
              "HR",
              "Product Head",
              "Senior Software Developer",
              "Software Developer",
              "Software Developer Intern",
              "Tech Head",
              "UI/UX Designer",
              "UI/UX Designer Intern",
            ]}
            {...getInputProps("role")}
          />
        </Box>

        {!data && (
          <PasswordField
            label="Password"
            name="password"
            getInputProps={getInputProps}
          />
        )}
        <Switch
          mb={15}
          name="remoteEmployee"
          label="Remote Employee"
          style={{
            marginBottom: "0.4rem",
            marginTop: "1.4rem",
            fontWeight: 400,
          }}
          styles={{
            label: {
              fontSize: "16px",
            },
          }}
          checked={getInputProps("remoteEmployee").value}
          onChange={(event) =>
            getInputProps("remoteEmployee").onChange(
              event.currentTarget.checked
            )
          }
        />
        <Button
          disabled={addLoading || updateLoading}
          loading={addLoading || updateLoading}
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
        >
          {data ? "Update Employee" : "Add Employee"}
        </Button>
      </form>
    </Modal>
  );
};
const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default memo(forwardRef(StudentModal));
