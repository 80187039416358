import { MonthPickerInput } from "@mantine/dates";
import AttendanceCard from "./component/AttendanceCard";
import { Box, Center, Flex, Group, Loader, Select, Title } from "@mantine/core";
import { useGetAllEmployeeAttendance } from "../../hooks/all-student-attendance/query/useAllEmployeeAttendance.query";
import React, { useMemo, useState, useEffect } from "react";
import AttendanceExport from "./component/data/AttendanceExport";
import { useGetAllStudent } from "../../hooks/students/query/getAllStudent.query";

const AllStudentAttendance: React.FC = () => {
  const [month, setMonth] = useState<Date>(new Date());
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null); // Changed initial state to null

  // Create list of students after data has been loaded
  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student: any) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  // Fetch attendance data based on selected student and month
  const { data, isLoading } = useGetAllEmployeeAttendance({
    date: month,
    studentId: selectedStudent || "", // Use empty string if no student is selected
  });

  const attendance: TAttendanceData[] = useMemo(() => {
    if (!isLoading && data?.data) {
      return data.data;
    } else {
      return [] as TAttendanceData[];
    }
  }, [isLoading, data, selectedStudent]);

  // Set default student as the first student after data is loaded
  useEffect(() => {
    if (students.length > 0 && selectedStudent === null) {
      setSelectedStudent(students[0].value); // Automatically select first student
    }
  }, [students, selectedStudent]);

  if (isLoading) {
    return (
      <Center h={"70vh"}>
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      <Box>
        <Flex
          mih={50}
          gap="md"
          justify="space-between"
          align="center"
          direction="row"
        >
          <Group>
            <Title order={3} weight={500} c="gray">
              Time Sheets
            </Title>
            <MonthPickerInput
              placeholder={month.toDateString()}
              value={month}
              onChange={(val) => {
                if (val) {
                  setMonth(val);
                }
              }}
            />
          </Group>
          <Group>
            <Select
              data={[{ value: "All", label: "All" }, ...students]}
              value={selectedStudent || "All"} // Use selectedStudent value
              onChange={(value) => {
                if (value) setSelectedStudent(value);
              }}
              searchable
            />
            <AttendanceExport
              date={month}
              studentId={selectedStudent || "All"}
            />
          </Group>
        </Flex>
      </Box>
      <AttendanceCard loading={isLoading} attendance={attendance} />
    </Box>
  );
};

export default AllStudentAttendance;
