import { Button, Text } from "@mantine/core";
import React, { memo } from "react";

import { ButtonProps } from "@mantine/core/lib/Button";
interface IButton extends ButtonProps {
  title: string;
  type?: "reset" | "submit" | "button" | undefined;
}

const ThemeButton = (props: IButton) => {
  const { title, type } = props;
  return (
    <Button {...props} style={{ backgroundColor: "#ff008a" }} type={type}>
      <Text>{title}</Text>
    </Button>
  );
};

export default memo(ThemeButton);
