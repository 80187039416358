import React, { memo } from "react";
import { Table } from "@mantine/core";
import NoData from "../../../no-data/NoData";
import { useLocation } from "react-router-dom";
import { COLORS } from "../../../../assets/colors/index.colors";

interface IStatusColors {
  absent: string;
  initiate: string;
  "on time": string;
  late: string;
  leave: string;
}

const DynamicTable: React.FC<TTableListView> = ({ data = [], columns }) => {
  const statusColors: IStatusColors = {
    absent: COLORS.ABSENT,
    initiate: COLORS.INITIATE,
    "on time": COLORS.ON_TIME,
    late: COLORS.LATE,
    leave: COLORS.LEAVE,
  };

  const route = useLocation();

  const headerStyles = (path: string) => {
    const base = { backgroundColor: "transparent", color: "#000" };
    if (path == "/employees") {
      base.backgroundColor = "transparent";
      base.color = "rgb(73, 80, 87)";
    }
    return base;
  };

  if (data.length === 0) {
    return <NoData title="No Data Found" />;
  }
  return (
    <Table
      horizontalSpacing="xl"
      verticalSpacing="md"
      highlightOnHover
      h={60}
      bg={route.pathname === "/" ? "#fff" : "transparent"}
      style={{ justifyContent: "space-between" }}
    >
      <thead>
        <tr>
          {columns.map((column) => (
            <th style={headerStyles(route.pathname)} key={column.key}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: any) => {
          if (!row["status"]) {
            return (
              <tr key={row._id}>
                {columns.map((column) => {
                  return (
                    <td key={column.key}>
                      {column.renderCell
                        ? column.renderCell(row)
                        : row[column.key]}
                    </td>
                  );
                })}
              </tr>
            );
          }

          return (
            <tr key={row._id}>
              {columns.map((column) => {
                const bgcolor =
                  statusColors[row["status"] as keyof IStatusColors];
                const isOnTime =
                  row["status"] === "on time" || row["status"] === "initiate";

                const style = {
                  // backgroundColor: bgcolor,
                  color: bgcolor ?? "black",
                };
                if (column.key === "status") {
                  return (
                    <td
                      key={column.key}
                      style={{ ...style, textTransform: "capitalize" }}
                    >
                      {row[column.key]}
                    </td>
                  );
                }
                if (column.key === "inTime" || column.key === "outTime") {
                  if (!row[column.key]) {
                    return (
                      <td key={column.key} style={style}>
                        NA
                      </td>
                    );
                  }
                  return (
                    <td key={column.key} style={style}>
                      {new Date(row[column.key]).toLocaleTimeString()}
                    </td>
                  );
                } else if (
                  column.key === "inNote" ||
                  column.key === "outNote"
                ) {
                  if (!row[column.key] || row[column.key] === "") {
                    return (
                      <td key={column.key} style={style}>
                        NA
                      </td>
                    );
                  }
                  return (
                    <td key={column.key} style={style}>
                      {row[column.key]}
                    </td>
                  );
                }
                return (
                  <td
                    key={column.key}
                    style={{ ...style, fontWeight: !isOnTime ? 700 : 400 }}
                  >
                    {column.renderCell
                      ? column.renderCell(row)
                      : row[column.key]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default memo(DynamicTable);
