import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllFeedback = async (params: TQueryParams) => {
  const response: TServerResponse<TFeedbackData[], unknown> = await request({
    url: apiUrls.feedback.GET_ALL_FEEDBACK,
    method: "GET",
    params: {
      ...params.paging,
      date: params.date,
      search: params.searchParams?.search,
      searchFieldString: ["employ.value"],
    },
  });

  return response;
};

export const useGetAllFeedback = (params: TQueryParams) => {
  return useQuery(["admin", "all-feedback", params], () =>
    getAllFeedback(params)
  );
};
