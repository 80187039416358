import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { TFeedbackValues } from "../../../form/initial-value/addFeedback.values";

const AddFeedback = async (data: TFeedbackValues) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.feedback.CREATE_FEEDBACK,
    method: "POST",
    data: data,
  });
  return response;
};

export const useAddFeedbackMutation = () => {
  return useMutation(AddFeedback);
};
