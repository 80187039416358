import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import React, { FC, memo } from "react";

interface IActionButton {
  handleClick: () => void;
}

const ActionButton: FC<IActionButton> = ({ handleClick }) => {
  return (
    <Group>
      <Tooltip label="Edit Employee">
        <ActionIcon
          variant="outline"
          color="blue"
          onClick={() => {
            handleClick();
          }}
        >
          <IconEdit />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

export default memo(ActionButton);
