import { useMutation } from "react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";
import { TProjectsValues } from "../../../form/initial-value/addProjects.values";

const updateProjects = async (data: TProjectsValues) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.projects.UPDATE_PROJECT,
    method: "POST",
    data: data,
  });
  return response;
};

export const useUpdateProjectsMutation = () => {
  return useMutation(updateProjects);
};
