import {
  IconHome,
  IconUsers,
  IconCalendar,
  IconCalendarOff,
  IconNotes,
  IconCalendarQuestion,
  IconFileAnalytics,
} from "@tabler/icons-react";

const navData = [
  { link: "/", label: "Dashboard", icon: IconHome },
  { link: "/employees", label: "Employees", icon: IconUsers },
  { link: "/attendance", label: "Attendance", icon: IconCalendar },
  { link: "/holidays", label: "Holidays", icon: IconCalendarOff },
  { link: "/worklog", label: "Work Log", icon: IconNotes },
  { link: "/projects", label: "Projects", icon: IconFileAnalytics },
  { link: "/leave", label: "Leave Management", icon: IconCalendarQuestion },
  {
    link: "/employ-feedback",
    label: "Employ Feedback",
    icon: IconCalendarQuestion,
  },
];

export default navData;
