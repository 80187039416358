import React, { useEffect, useState } from "react";
import {
  Flex,
  Table,
  ScrollArea,
  createStyles,
  Box,
  ActionIcon,
  rem,
  Loader,
  Select,
  Center,
  Tooltip,
} from "@mantine/core";
import SearchField from "../../component/search-field/SearchField";
import { NoteTile } from "./components/NoteTile";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";
import { useWorkLogDetailsQuery } from "../../hooks/students/query/getWorkLogDetails.query";
import NoData from "../../component/no-data/NoData";
import { useGetWorkLog } from "../../hooks/notes/getWorkLog.query";
import { useDebouncedValue } from "@mantine/hooks";

export const WorkLog: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [search, setSearch] = useState<string>("");
  const [studentId, setStudentId] = useState<string | undefined>(undefined);
  const [debouncedId] = useDebouncedValue<string | undefined>(studentId, 300);
  const [date, setDate] = useState<Date>(new Date());
  const [noteType, setNoteType] = useState<string | null>(null);
  const [debouncedDate] = useDebouncedValue<Date>(date, 300);
  const {
    data: todayNotes,
    isLoading: isTodayNoteLoading,
    refetch,
  } = useGetWorkLog(debouncedDate, noteType, debouncedId);
  const { data, isLoading } = useWorkLogDetailsQuery({
    paging: {
      itemPerPage: 500,
      page: 1,
    },
    date: debouncedDate,
    search: search,
  });

  useEffect(() => {
    refetch();
  }, [noteType]);

  const selectData = [
    { label: "All", value: "" },
    { label: "Notes", value: "note" },
    { label: "Tasks", value: "task" },
  ];

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleRowClick = (id: number, studentId: string) => {
    setSelectedRow(id);
    setStudentId(studentId);
  };
  const { classes } = useStyles();

  return (
    <Flex direction="row" justify="center" align="flex-start" wrap={"wrap"}>
      <Flex direction="column" justify="center" align="center" w="50%">
        <Box className={classes.flex}>
          <Box className={classes.people}>
            <SearchField onChangeText={setSearch} />
          </Box>
          <Flex m={18}>
            <ActionIcon
              onClick={() => setDate(moment(date).subtract(1, "day").toDate())}
              size={"lg"}
              variant="light"
              bg={"white"}
            >
              <IconChevronLeft size={16} />
            </ActionIcon>
            <DatePickerInput
              maxDate={new Date()}
              styles={{ input: { border: "none" } }}
              icon={
                <IconCalendar
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={1.5}
                />
              }
              placeholder="Pick date"
              value={date}
              onChange={(val) => {
                if (val) {
                  setDate(val);
                }
              }}
            />
            <ActionIcon
              disabled={date ? isToday(date) : false}
              onClick={() => setDate(moment(date).add(1, "day").toDate())}
              size={"lg"}
              variant="light"
              bg={"white"}
            >
              <IconChevronRight size={16} />
            </ActionIcon>
          </Flex>
        </Box>
        <ScrollArea h="70vh" w="100%" scrollbarSize={8}>
          {isLoading && (
            <Center h={"25vh"}>
              <Loader />
            </Center>
          )}
          {!isLoading && (
            <Table
              horizontalSpacing="xl"
              verticalSpacing="md"
              highlightOnHover
              sx={{
                width: "100%",
                margin: "auto",
                border: "2px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Roll No</th>
                  <th>Note Count</th>
                  <th>Task Count</th>
                </tr>
              </thead>
              <tbody>
                {(data?.data as [])?.map((student) => {
                  const status = (student as { status: string }).status;
                  const noteLength = (student as { noteLength: number })
                    .noteLength;
                  const taskLength = (student as { taskLength: number })
                    .taskLength;
                  const zeroNotes = noteLength === 0 || taskLength === 0;
                  const inActive = status === "leave" || status === "absent";

                  const tooltipMessage = inActive
                    ? `${status?.toUpperCase()}${
                        !zeroNotes
                          ? ` With Total ${noteLength + taskLength} Note/Task`
                          : ""
                      }`
                    : noteLength === 0 && taskLength === 0
                    ? "EMPTY NOTES"
                    : `Total ${noteLength + taskLength} Note/Task`;

                  return (
                    <Tooltip.Floating
                      label={tooltipMessage}
                      key={student["_id"]}
                      //   disabled={!inActive && !zeroNotes}
                      position="bottom-start"
                      styles={{
                        tooltip: {
                          background: "#fff",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                          color: "black",
                          fontSize: 12,
                          fontWeight: 500,
                        },
                      }}
                    >
                      <tr
                        key={student["_id"]}
                        onClick={() => {
                          handleRowClick(student["_id"], student["_id"]);
                        }}
                        style={{
                          backgroundColor:
                            selectedRow === student["_id"]
                              ? "#f0f0f0"
                              : "white",
                          cursor: "pointer",
                          color: inActive
                            ? "rgb(250, 82, 82)"
                            : zeroNotes
                            ? "rgb(176, 153, 3)"
                            : undefined,
                        }}
                      >
                        <td>{student["name"]}</td>
                        <td>{student["rollNumber"]}</td>
                        <td style={{ textAlign: "center" }}>{noteLength}</td>
                        <td style={{ textAlign: "center" }}>{taskLength}</td>
                      </tr>
                    </Tooltip.Floating>
                  );
                })}
              </tbody>
            </Table>
          )}
        </ScrollArea>
      </Flex>
      <Flex direction="column" justify="flex-start" align="flex-end" w="50%">
        {isTodayNoteLoading && (
          <Center mih={"50vh"} miw={"100%"}>
            <Loader />
          </Center>
        )}
        {!isLoading && !isTodayNoteLoading && (
          <Select
            data={selectData}
            defaultValue={""}
            onChange={async (e) => {
              setNoteType(e);
            }}
          />
        )}
        {!isLoading &&
          !isTodayNoteLoading &&
          todayNotes?.data !== undefined &&
          (todayNotes?.data as []).length === 0 && (
            <NoData
              title={debouncedId ? "No Notes Today" : "Please Select Employee"}
            />
          )}
        {!isLoading &&
          !isTodayNoteLoading &&
          todayNotes?.data !== undefined &&
          (todayNotes?.data as []).length !== 0 && (
            <ScrollArea h="80vh" w="100%" scrollbarSize={8}>
              {(todayNotes?.data as [])?.map((note) => (
                <NoteTile
                  note={note["note"]}
                  key={note["_id"]}
                  isTask={note["isTask"]}
                />
              ))}
            </ScrollArea>
          )}
      </Flex>
    </Flex>
  );
};

const useStyles = createStyles(() => ({
  flex: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    border: "1px solid #D3D3D3",
    borderRadius: 5,
    marginBottom: 8,
    marginTop: 8,
  },

  people: {
    display: "flex",
    alignItems: "center",
  },
}));
