export type TProjectsValues = {
  name: string;
  status: string;
  projectId: string;
  _id?: string;
};

export const AddProjectsValues: TProjectsValues = {
  name: "",
  projectId: "",
  status: "",
  _id: "",
};
