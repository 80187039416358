import { useMutation } from "react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";
import { TBirthdayValues } from "../../../form/initial-value/birthdayMessage.value";

const updateBirthdayMessage = async (data: TBirthdayValues) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.UPDATE_BIRTHDAY_MESSAGE,
    method: "POST",
    data: data,
  });
  return response;
};

export const useUpdateBirthdayMessage = () => {
  return useMutation(updateBirthdayMessage);
};
