import { object, string } from "yup";

export const AddFeedbackValidationSchema = object({
  feedback: string().required("Feedback filed is required"),
  employ: object()
    .shape({
      value: string().required("Employ ID is required"),
      label: string().required("Employ name is required"),
    })
    .required("Employ field is required"),
});
