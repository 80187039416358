import { Button, Modal, Select, Switch, createStyles } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm, yupResolver } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useState,
  useEffect,
} from "react";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../../form/initial-value";
import { validations } from "../../../form/validation";
import InputField from "../../../component/form/input-field/InputField";
import SelectField from "../../../component/form/select-field/SelectField";
import { useAddProjectsMutation } from "../../../hooks/projects/mutation/addProjects.mutation";
import { useUpdateProjectsMutation } from "../../../hooks/projects/mutation/updateProjects.mutation";
import { TProjectsValues } from "../../../form/initial-value/addProjects.values";

export interface IProjectsModalRef {
  toggleModal: () => void;
  updateData: (student: TProjectsData) => void;
}
interface IProjectsModalProps {
  reload: () => void;
}

const ProjectsModal = (
  props: IProjectsModalProps,
  ref: ForwardedRef<IProjectsModalRef>
) => {
  const [data, setData] = useState<TProjectsData>();
  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { isLoading: addLoading, mutateAsync: addStudent } =
    useAddProjectsMutation();
  const { isLoading: updateLoading, mutateAsync: updateStudent } =
    useUpdateProjectsMutation();
  const { getInputProps, onSubmit, reset, setValues } = useForm({
    initialValues: initialValue.AddProjectsValues,
    validate: yupResolver(validations.projects),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (data) {
      setValues(data);
    } else {
      setValues(initialValue.AddProjectsValues);
    }
  }, [setValues, data]);

  const handleFormSubmit = useCallback(
    async (values: TProjectsValues) => {
      const res = data ? await updateStudent(values) : await addStudent(values);
      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [addStudent, toggle, reload, reset, updateStudent, data]
  );
  const handelCloseModal = useCallback(() => {
    toggle();
    setData(undefined);
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleModal: handelCloseModal,
        updateData: (d) => setData(d),
      };
    },
    [handelCloseModal]
  );

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: " all 0.2s ease-in-out 0s;",
          },
        },
      }}
      opened={opened}
      onClose={handelCloseModal}
      title={data ? "Edit Project" : "Add Project"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <InputField
          label="Project Name"
          name="name"
          getInputProps={getInputProps}
        />
        <InputField
          label="Project Id"
          name="projectId"
          getInputProps={getInputProps}
          disabled={data && true}
        />

        <SelectField
          label="Select Project Status"
          name="status"
          getInputProps={getInputProps}
        />

        <Button
          disabled={addLoading || updateLoading}
          loading={addLoading || updateLoading}
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
        >
          {data ? "Update Project" : "Add Project"}
        </Button>
      </form>
    </Modal>
  );
};
const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default memo(forwardRef(ProjectsModal));
