import { Box, Select, Text, TextInput, TextInputProps } from "@mantine/core";
import { GetInputProps } from "@mantine/form/lib/types";
import React, { memo } from "react";

interface ISelectField {
  label: string;
  getInputProps: GetInputProps<TextInputProps>;
  name: string;
}

const SelectField: React.FC<ISelectField> = ({
  label,
  getInputProps,
  name,
}) => {
  return (
    <Box>
      <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
        {label}
      </Text>
      <Select
        searchable
        nothingFound="No options"
        data={["New", "Open", "Close"]}
        {...getInputProps(name)}
      />
    </Box>
  );
};

export default memo(SelectField);
