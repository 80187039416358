import { Box, createStyles, Group, Select } from "@mantine/core";
import React, { useMemo, useRef, useState } from "react";
import { COLORS } from "../../assets/colors/index.colors";
import { useGetAllStudent } from "../../hooks/students/query/getAllStudent.query";
import ThemeButton from "../../component/button/ThemeButton";
import { IconPlus } from "@tabler/icons-react";
import FeedbackModal from "./modal/FeedbackModal";
import CustomTable from "../../component/table";
import { COLUMNS } from "../../columns";
import { useGetAllFeedback } from "../../hooks/feedback/query/getAllFeedback.query";
import { CONSTANTS } from "../../constant";
import { YearPickerInput } from "@mantine/dates";

const EmployFeedback = () => {
  const modalRef = useRef<any>(null);
  const { classes } = useStyles();
  const [year, setYear] = useState<Date | null>(new Date());
  const [selectedStudent, setSelectedStudent] = useState<string>("All");
  const tableColumns = [...COLUMNS.feedback];
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student: any) => ({
        value: student["_id"],
        label: student["name"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });

  const { refetch, data, isLoading } = useGetAllFeedback({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    date: year ?? undefined,
    searchParams: {
      search: selectedStudent === "All" ? "" : selectedStudent,
    },
  });

  const feedback: TFeedbackData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Box className={classes.root}>
      <Group position="apart" style={{ marginBottom: 20 }}>
        <Group>
          <Select
            data={[{ value: "All", label: "All" }, ...students]}
            defaultValue={"All"}
            onChange={(value) => {
              if (value) setSelectedStudent(value);
            }}
            searchable
          />
          <YearPickerInput
            placeholder="Search For Year"
            value={year}
            onChange={(val) => {
              if (val) {
                setYear(val);
              }
            }}
          />
        </Group>

        <Box onClick={() => modalRef.current?.toggleModal()}>
          <ThemeButton
            title="Add Feedback"
            mr={15}
            leftIcon={<IconPlus size={20} />}
          />
        </Box>
      </Group>
      <Box>
        <FeedbackModal reload={refetch} ref={modalRef} />
      </Box>
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={feedback}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
    </Box>
  );
};

export default EmployFeedback;

const useStyles = createStyles(() => ({
  root: {
    padding: "16px 30px",
    background: COLORS.white,
  },
}));
