import { Badge, Card, Flex, Text } from "@mantine/core";
import React from "react";

export const NoteTile = (props: { note: string; isTask: boolean }) => {
  const isHtmlContent = (text: string): boolean => /<\/?[a-z][\s\S]*>/i.test(text);
  return (
    <Card
      mx={20}
      my={10}
      shadow="sm"
      radius="sm"
      sx={{
        borderLeft: "4px solid #ff008a",
      }}
    >
      <Flex justify={"space-between"} align={"center"}>
      {isHtmlContent(props.note) ? (
          <Text
            style={{ flex: 1 }}
            c={"black"}
            dangerouslySetInnerHTML={{ __html: props.note }}
          />
        ) : (
          <Text style={{ flex: 1 }} c={"black"}>
            {props.note}
          </Text>
        )}
        {props.isTask && <Badge>Task</Badge>}
      </Flex>
    </Card>
  );
};
