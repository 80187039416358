import { Box, Button, Modal, Select, Text, createStyles } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useForm, yupResolver } from "@mantine/form";
import React, {
  memo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
  useState,
  useMemo,
} from "react";
import { notifications } from "@mantine/notifications";
import { initialValue } from "../../../form/initial-value";
import { validations } from "../../../form/validation";
import { useGetAllStudent } from "../../../hooks/students/query/getAllStudent.query";
import Textarea from "../../../component/form/text-area/Textarea";
import { TFeedbackValues } from "../../../form/initial-value/addFeedback.values";
import { useAddFeedbackMutation } from "../../../hooks/feedback/mutation/addFeedback.mutation";

export interface IProjectsModalRef {
  toggleModal: () => void;
  updateData: (student: TProjectsData) => void;
}
interface IProjectsModalProps {
  reload: () => void;
}

const ProjectsModal = (
  props: IProjectsModalProps,
  ref: ForwardedRef<IProjectsModalRef>
) => {
  const { data: studentsData, isLoading: studentLoading } = useGetAllStudent({
    blocked: false,
  });

  const { isLoading: createLoading, mutateAsync: createFeedback } =
    useAddFeedbackMutation();

  const students = useMemo(() => {
    if (!studentLoading && studentsData?.data) {
      return (studentsData.data as []).map((student: any) => ({
        value: student["_id"],
        label: student["name"],
        email: student["email"],
      }));
    }
    return [];
  }, [studentLoading, studentsData]);

  const [data, setData] = useState<TProjectsData>();
  const { reload } = props;
  const [opened, toggle] = useToggle();
  const { classes } = useStyles();
  const { getInputProps, onSubmit, reset } = useForm({
    initialValues: initialValue.AddFeedbackValues,
    validate: yupResolver(validations.feedback),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleFormSubmit = useCallback(
    async (values: TFeedbackValues) => {
      const res = await createFeedback(values);
      if (res.status === "success") {
        reload();
        toggle();
        reset();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        notifications.show({
          color: "red",
          message: res.message,
        });
      }
    },
    [createFeedback, toggle, reload, reset]
  );

  const handelCloseModal = useCallback(() => {
    toggle();
  }, [toggle]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleModal: handelCloseModal,
        updateData: (d) => setData(d),
      };
    },
    [handelCloseModal]
  );

  return (
    <Modal
      styles={{
        title: { fontSize: "1.3rem", fontWeight: 500 },
        close: {
          color: "#ff008a",
          "&:hover": {
            backgroundColor: "#ff008a",
            color: "white",
            transition: " all 0.2s ease-in-out 0s;",
          },
        },
      }}
      opened={opened}
      onClose={handelCloseModal}
      title={"Add Feedback"}
      centered
    >
      <form onSubmit={onSubmit(handleFormSubmit)}>
        <Textarea
          label="Feedback "
          name="feedback"
          getInputProps={getInputProps}
        />
        <Box>
          <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
            Select Employ
          </Text>
          <Select
            data={students}
            searchable
            {...getInputProps("employ")}
            value={getInputProps("employ").value?.value}
            onChange={(value) => {
              const selectedStudent = students.find(
                (student) => student.value === value
              );
              getInputProps("employ").onChange(selectedStudent);
            }}
            error={getInputProps("employ.label").error}
          />
        </Box>

        <Button
          type="submit"
          fullWidth
          mt="xl"
          className={classes.btn}
          loading={createLoading}
          disabled={createLoading}
        >
          Add Feedback
        </Button>
      </form>
    </Modal>
  );
};
const useStyles = createStyles(() => ({
  btn: {
    background: "#ff008a",
    height: "45px",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default memo(forwardRef(ProjectsModal));
