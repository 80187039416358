import { useMutation } from "react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";
import { TSettingsValues } from "../../../form/initial-value/settings.values";

const updateReferenceSaturday = async (referenceSaturday: Date) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.UPDATE_REFERENCE_SATURDAY,
    method: "POST",
    data: {
      referenceSaturday: referenceSaturday,
    },
  });
  return response;
};

export const useUpdateReferenceSaturday = () => {
  return useMutation(updateReferenceSaturday);
};
