import { Box } from "@mantine/core";
import React, { useMemo, useRef, useState } from "react";
import CustomTable from "../../component/table";
import ThemeButton from "../../component/button/ThemeButton";
import { IconPlus } from "@tabler/icons-react";
import { COLUMNS } from "../../columns";
import ProjectsModal, { IProjectsModalRef } from "./modal/ProjectsModal";
import { CONSTANTS } from "../../constant";
import { useGetAllProjects } from "../../hooks/projects/query/getAllProjects.query";
import ActionButton from "./components/ActionButton";

const Projects = () => {
  const modalRef = useRef<IProjectsModalRef>(null);
  const tableColumns = [...COLUMNS.projects];

  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState<TPageData>({
    total: 0,
  });
  const [search, setSearch] = useState("");

  const { refetch, data, isLoading } = useGetAllProjects({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
    searchParams: {
      search,
    },
  });

  const projects: TProjectsData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  tableColumns.push({
    header: "Project Actions",
    key: "editProjects",
    renderCell: (row: TProjectsData) => (
      <ActionButton
        handleClick={() => {
          modalRef.current?.toggleModal();
          modalRef.current?.updateData(row);
        }}
      />
    ),
  });

  return (
    <Box>
      <ProjectsModal reload={refetch} ref={modalRef} />
      <CustomTable
        isDashboardTable={false}
        loading={isLoading}
        columns={tableColumns}
        data={projects}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
        headerProps={{
          search: true,
          onChangeText: (text) => setSearch(text),
          rightComponent: (
            <Box onClick={() => modalRef.current?.toggleModal()}>
              <ThemeButton
                title="Add Project"
                mr={15}
                leftIcon={<IconPlus size={20} />}
              />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default Projects;
