export type TStudentValues = {
  name: string;
  mobile: string;
  email: string;
  rollNumber: string;
  password: string;
  remoteEmployee: boolean;
  _id?: string;
  dob: Date | string;
  role: string;
};

export const AddStudentValues: TStudentValues = {
  name: "",
  mobile: "",
  email: "",
  rollNumber: "",
  password: "",
  remoteEmployee: false,
  _id: "",
  dob: "",
  role: "",
};
