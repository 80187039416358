export const getFirstTwoSaturdayOfYear = (date: Date) => {
  const first = new Date(date);

  const dayOfWeek = first.getDay();

  const daysUntilSaturday = (6 - dayOfWeek + 7) % 7;

  first.setDate(first.getDate() + daysUntilSaturday);
  const second = new Date(first);
  second.setDate(first.getDate() + 7);
  first.setHours(0, 0, 0, 0);
  second.setHours(0, 0, 0, 0);

  return { first, second };
};
